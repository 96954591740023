@font-face {
  font-family: 'proxima-nova';
  src: url('fonts/34BD93_8_0.eot');
  src: url('fonts/34BD93_8_0.eot?#iefix') format('embedded-opentype'), url('fonts/34BD93_8_0.woff2') format('woff2'), url('fonts/34BD93_8_0.woff') format('woff'), url('fonts/34BD93_8_0.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('fonts/34BD93_9_0.eot');
  src: url('fonts/34BD93_9_0.eot?#iefix') format('embedded-opentype'), url('fonts/34BD93_9_0.woff2') format('woff2'), url('fonts/34BD93_9_0.woff') format('woff'), url('fonts/34BD93_9_0.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('fonts/34BD93_A_0.eot');
  src: url('fonts/34BD93_A_0.eot?#iefix') format('embedded-opentype'), url('fonts/34BD93_A_0.woff2') format('woff2'), url('fonts/34BD93_A_0.woff') format('woff'), url('fonts/34BD93_A_0.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('fonts/34BD93_B_0.eot');
  src: url('fonts/34BD93_B_0.eot?#iefix') format('embedded-opentype'), url('fonts/34BD93_B_0.woff2') format('woff2'), url('fonts/34BD93_B_0.woff') format('woff'), url('fonts/34BD93_B_0.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html { color: theme('colors.charcoal'); }

  html, body {
    height: 100%;
  }

  #root {
    height: 100%;
  }
}

.library-grid {
  grid-template-columns: repeat(auto-fill, 163px);
}

@media (min-width: 1024px) {
  .photo-gallery-grid {
    grid-template-columns: repeat(auto-fill, 240px);
  }

  .library-grid {
    grid-template-columns: repeat(auto-fill, 184px);
  }
}

.modal-centered {
  --marginY: 1.75rem;
  --totalMarginY: var(--marginY) * 2;
  min-height: calc(100% - var(--totalMarginY));
  margin: var(--marginY) auto;
}

.carousel__back-button[disabled],
.carousel__next-button[disabled] {
  display: none;
}

.sliderAnimation___300FY {
  transition: initial;
}

.modal-open {
  overflow-y: hidden;
}
